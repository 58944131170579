"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.WineryCreateScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var router_provider_1 = require("@codebuild/glaze/libs/router/router.provider");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var required_validator_1 = require("@codebuild/sprinkles/libs/form/validators/required.validator");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var button_1 = require("@codebuild/sprinkles/modules/codebuild/button/react/button");
var checkbox_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/checkbox.form-control-type");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var text_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/text-input.form-control-type");
var checkbox_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/checkbox-input");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var text_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/text-input");
var textarea_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/textarea-input");
var lodash_1 = require("lodash");
var React = require("react");
var multi_language_input_1 = require("../../../common/components/multi-language-input");
var sticky_container_1 = require("../../../common/components/sticky-container");
var countries_1 = require("../../../common/libs/countries");
var form_1 = require("../../../components/form/form");
var multi_image_uploader_1 = require("../../../components/image-uploader/multi-image-uploader");
var handle_toasts_1 = require("../../../components/libs/handle.toasts");
var message_box_1 = require("../../../components/libs/message-box/message-box");
var prizes_form_1 = require("../components/prizes-form");
require("./manufacturer-create.screen.scss");
var languageOptions = [
    { value: 'hu', name: 'HU', key: 'hu' },
    { value: 'en', name: 'EN', key: 'en' }
];
var WineryCreateScreen = (function (_super) {
    __extends(WineryCreateScreen, _super);
    function WineryCreateScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.form = new form_builder_1.FormBuilder({
            fields: {
                commissioner: new field_1.Field({
                    value: true,
                    name: 'commissioner',
                    label: (0, trans_1.trans)('winery.form.commissioner'),
                    validators: []
                }),
                showOnB2C: new field_1.Field({
                    value: false,
                    name: 'showOnB2C',
                    label: (0, trans_1.trans)('winery.form.show-on-b2c'),
                    validators: []
                }),
                name: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'name',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.name'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                code: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'code',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.code'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                huTaxNumber: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'huTaxNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.huTaxNumber'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                euTaxNumber: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'euTaxNumber',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.euTaxNumber'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                heaCode: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'heaCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.heaCode'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                description: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'description',
                    label: (0, trans_1.trans)('winery.form.description'),
                    validators: []
                }),
                area: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'area',
                    label: (0, trans_1.trans)('winery.form.area'),
                    validators: []
                }),
                grape_variety: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'grape_variety',
                    label: (0, trans_1.trans)('winery.form.grape_variety'),
                    validators: []
                }),
                vineyard: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'vineyard',
                    label: (0, trans_1.trans)('winery.form.vineyard'),
                    validators: []
                }),
                soil: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'soil',
                    label: (0, trans_1.trans)('winery.form.soil'),
                    validators: []
                }),
                technology: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'technology',
                    label: (0, trans_1.trans)('winery.form.technology'),
                    validators: []
                }),
                country: new field_1.Field({
                    placeholder: '',
                    value: 'HU',
                    name: 'country',
                    options: countries_1.COUNTRIES,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.country'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                city: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'city',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.city'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                zipCode: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'zipCode',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.zipCode'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                address: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'address',
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.address'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))]
                }),
                region: new field_1.Field({
                    placeholder: '',
                    value: '',
                    name: 'region',
                    optionsMap: function (response) { return response.items.map(function (o) { return ({ name: o.title.hu, value: o._id, key: o._id }); }); },
                    optionsEndpoint: '/admin/region?_limit=10000',
                    loadOptionsAfterMount: true,
                    label: React.createElement("div", null,
                        (0, trans_1.trans)('winery.form.region'),
                        " ",
                        React.createElement("i", { className: 'color--status-error-1' }, "*")),
                    validators: [
                        new required_validator_1.RequiredValidator((0, trans_1.trans)('form.validation.required'))
                    ]
                }),
            }
        });
        _this.state = {
            editorLanguage: 'hu',
            error: null
        };
        return _this;
    }
    WineryCreateScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", { className: 'ManufacturerCreateScreen row w-100' },
            React.createElement("div", { className: 'col-20' },
                React.createElement("div", { className: 'mb-4' },
                    React.createElement("h2", null, (0, trans_1.trans)('winery-create.screen.title'))),
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: "col-24" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('winery-create.subtitle.basic-data'))),
                        this.renderBasicData())),
                React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-24" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.images'))),
                        this.renderImageUploader())),
                React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-24" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.attributes'))),
                        this.renderAttributes())),
                React.createElement("div", { className: 'my-4 border-color--brand-primary-1 border-bottom-2' }),
                React.createElement("div", { className: "row" },
                    React.createElement("div", { className: "col-24" },
                        React.createElement("div", { className: 'mb-3' },
                            React.createElement("h4", null, (0, trans_1.trans)('product.update.subtitle.prizes'))),
                        React.createElement(prizes_form_1.PrizesForm, { ref: function (ref) { return _this.productPrizesFormRef$ = ref; }, editorLanguage: this.state.editorLanguage, values: this.state.product })))),
            React.createElement("div", { className: 'col-4' }, this.renderSaveBox()));
    };
    WineryCreateScreen.prototype.renderBasicData = function () {
        var _a;
        return React.createElement("div", { className: 'WineryCreateForm' },
            React.createElement("div", null,
                React.createElement(form_1.Form, null,
                    React.createElement("div", null,
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.commissioner, type: checkbox_form_control_type_1.CheckboxFormControlType },
                            React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' })),
                        React.createElement(form_control_1.FormControl, { field: this.form.fields.showOnB2C, type: checkbox_form_control_type_1.CheckboxFormControlType },
                            React.createElement(checkbox_input_1.CheckboxInput, { className: 'checkbox-input-basic' }))),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.name, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(multi_language_input_1.MultiLanguageInput, { currentLanguage: this.state.editorLanguage, className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.code, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.huTaxNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.euTaxNumber, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.heaCode, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.country, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { unclearable: true, className: 'select-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.city, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.address, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.zipCode, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(text_input_1.TextInput, { className: 'text-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.region, type: select_input_form_control_type_1.SelectInputFormControlType },
                        React.createElement(select_input_1.SelectInput, { searchable: true, className: 'select-input-basic' })),
                    React.createElement(form_control_1.FormControl, { field: this.form.fields.description, type: text_input_form_control_type_1.TextInputFormControlType },
                        React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, currentLanguage: this.state.editorLanguage, rows: 12, className: 'textarea-input-basic' })))),
            React.createElement("div", { className: 'display-flex flex-row justify-content-end' }),
            React.createElement("div", { className: 'py-6' }, !!this.state.error &&
                React.createElement(message_box_1.MessageBox, { type: 'error', message: (0, trans_1.trans)("user.create.".concat((0, trans_1.trans)((_a = this.state.error) === null || _a === void 0 ? void 0 : _a.message))) })));
    };
    WineryCreateScreen.prototype.renderAttributes = function () {
        return React.createElement("div", null,
            React.createElement(form_control_1.FormControl, { field: this.form.fields.area, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { currentLanguage: this.state.editorLanguage, className: 'text-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.grape_variety, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { currentLanguage: this.state.editorLanguage, className: 'text-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.vineyard, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, currentLanguage: this.state.editorLanguage, rows: 4, className: 'textarea-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.soil, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, currentLanguage: this.state.editorLanguage, rows: 4, className: 'textarea-input-basic' })),
            React.createElement(form_control_1.FormControl, { field: this.form.fields.technology, type: text_input_form_control_type_1.TextInputFormControlType },
                React.createElement(multi_language_input_1.MultiLanguageInput, { input: textarea_input_1.TextareaInput, currentLanguage: this.state.editorLanguage, rows: 4, className: 'textarea-input-basic' })));
    };
    WineryCreateScreen.prototype.renderImageUploader = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: 'mb-4' },
                React.createElement(multi_image_uploader_1.MultiImageUploader, { ref: function (ref) { return _this.imageUploaderRef$ = ref; }, value: [], targetVariant: "fullpage", width: 368, heigth: 368 })));
    };
    WineryCreateScreen.prototype.renderSaveBox = function () {
        var _this = this;
        return React.createElement(sticky_container_1.StickyContainer, { topSpacing: 32, className: 'w-100' },
            React.createElement("div", { className: 'SaveBox' },
                React.createElement("div", { className: "mb-4" },
                    React.createElement(select_input_1.SelectInput, { className: 'select-input-basic', value: this.state.editorLanguage, onChange: function (value) { return _this.changeLanguage(value); }, unclearable: true, options: languageOptions })),
                React.createElement("div", { className: 'w-100 display-flex flex-column justify-content-center' },
                    React.createElement(button_1.Button, { className: 'button-primary-outline button-size-normal mb-2', title: (0, trans_1.trans)('general.button.title.cancel'), onClick: function () { return _this.cancelCreate(); } }),
                    React.createElement(button_1.Button, { className: 'button-primary-normal button-size-normal', title: (0, trans_1.trans)('general.button.title.save'), onClick: function () { return _this.createWinery(); } }))));
    };
    WineryCreateScreen.prototype.cancelCreate = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.form.updateFields({})];
                    case 1:
                        _a.sent();
                        return [4, this.form.clear()];
                    case 2:
                        _a.sent();
                        router_provider_1.RouterProvider.goTo('/winery');
                        return [2];
                }
            });
        });
    };
    WineryCreateScreen.prototype.createWinery = function () {
        return __awaiter(this, void 0, void 0, function () {
            var formErrors, prizes, images, hasError_1, data, parsedData, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 6]);
                        return [4, this.form.validate()];
                    case 1:
                        _a.sent();
                        return [4, this.form.getErrors()];
                    case 2:
                        formErrors = _a.sent();
                        return [4, this.productPrizesFormRef$.getData()];
                    case 3:
                        prizes = _a.sent();
                        images = this.imageUploaderRef$.getValue();
                        hasError_1 = prizes === null || prizes === void 0 ? void 0 : prizes.error;
                        (0, lodash_1.map)(__assign({}, formErrors), function (e) {
                            hasError_1 = hasError_1 || !!e.length;
                        });
                        if (hasError_1) {
                            return [2];
                        }
                        data = this.form.toJSON();
                        parsedData = __assign(__assign({}, data), { type: 'winery', prizes: prizes === null || prizes === void 0 ? void 0 : prizes.values, images: images, attributes: {
                                area: data === null || data === void 0 ? void 0 : data.area,
                                soil: data === null || data === void 0 ? void 0 : data.soil,
                                grape_variety: data === null || data === void 0 ? void 0 : data.grape_variety,
                                vineyard: data === null || data === void 0 ? void 0 : data.vineyard,
                                technology: data === null || data === void 0 ? void 0 : data.technology,
                            } });
                        return [4, this.repository.post('/admin/manufacturer', { data: parsedData })];
                    case 4:
                        _a.sent();
                        handle_toasts_1.HandleToasts.success((0, trans_1.trans)('winery.create.success.toast'));
                        router_provider_1.RouterProvider.goTo('/winery');
                        return [3, 6];
                    case 5:
                        e_1 = _a.sent();
                        this.setState({ error: e_1 });
                        return [3, 6];
                    case 6: return [2];
                }
            });
        });
    };
    WineryCreateScreen.prototype.changeLanguage = function (lang) {
        this.setState({ editorLanguage: lang });
    };
    return WineryCreateScreen;
}(abstract_component_1.AbstractComponent));
exports.WineryCreateScreen = WineryCreateScreen;
