"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductSalesReportScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var field_1 = require("@codebuild/sprinkles/libs/form/field");
var form_builder_1 = require("@codebuild/sprinkles/libs/form/form-builder");
var form_control_1 = require("@codebuild/sprinkles/libs/form/form-control/form-control");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var select_input_form_control_type_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/form-control-types/select-input.form-control-type");
var select_input_1 = require("@codebuild/sprinkles/modules/codebuild/input-fields/react/select-input");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var filter_group_1 = require("../../common/components/filter/filter-group");
var customer_partner_select_component_1 = require("../../common/components/list-search/customer-partner-select.component");
var list_date_range_filter_component_1 = require("../../common/components/list-search/list-date-range-filter.component");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var supplier_filter_component_1 = require("../../common/components/list-search/supplier-filter.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var report_download_1 = require("../../components/report-download");
var channelPriceOptions = [
    { name: 'HORECA', value: 'horeca', key: 0 },
    { name: 'HIPER', value: 'hiper', key: 2 },
    { name: 'B2B', value: 'b2b', key: 3 },
    { name: 'B2C', value: 'b2c', key: 4 },
];
var ProductSalesReportScreen = (function (_super) {
    __extends(ProductSalesReportScreen, _super);
    function ProductSalesReportScreen() {
        var _this = this;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                _limit: 10,
                _page: (_c = (_b = (_a = _this.props.route) === null || _a === void 0 ? void 0 : _a.query) === null || _b === void 0 ? void 0 : _b._page) !== null && _c !== void 0 ? _c : 0,
                query: (_f = (_e = (_d = _this.props.route) === null || _d === void 0 ? void 0 : _d.query) === null || _e === void 0 ? void 0 : _e.query) !== null && _f !== void 0 ? _f : ''
            },
        });
        _this.state = {
            total: 0,
            csvLoading: false,
            isFilterOpen: true,
            openedRows: []
        };
        _this.filterForm = new form_builder_1.FormBuilder({
            fields: {
                channel: new field_1.Field({
                    name: 'channel',
                    label: (0, trans_1.trans)('b2b-channel-pricing.filter.form.label.channel'),
                    value: ((_h = (_g = _this.control) === null || _g === void 0 ? void 0 : _g.getQuery()) === null || _h === void 0 ? void 0 : _h.channel) || '',
                    options: channelPriceOptions,
                    loadOptionsAfterMount: true,
                    validators: []
                })
            }
        });
        return _this;
    }
    ProductSalesReportScreen.prototype.componentDidMount = function () {
        var _this = this;
        this.filterForm.fields.channel.$value.subscribe(function (v) { return _this.handleChange('channel', v); });
    };
    ProductSalesReportScreen.prototype.handleChange = function (property, value) {
        this.setState({ groupedProducts: [] });
        this.control.set(property, value);
        this.control.page(0);
    };
    ProductSalesReportScreen.prototype.render = function () {
        var _this = this;
        return React.createElement("div", null,
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15" },
                    React.createElement("div", { className: 'display-flex align-items-center mb-8' },
                        React.createElement("h2", { className: 'mr-4' }, (0, trans_1.trans)('product-sales-report.list.title')),
                        React.createElement("span", null,
                            this.state.total,
                            " db"))),
                React.createElement("div", { className: "col-9" },
                    React.createElement("div", { className: 'display-flex align-items-center justify-content-end' },
                        React.createElement(report_download_1.ReportDownload, { url: '/admin/sales-by-products-report/download', control: this.control })))),
            React.createElement("div", { className: 'row mt-4 mb-7' },
                React.createElement(filter_group_1.FilterGroup, { renderFilters: React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-12" },
                            React.createElement(customer_partner_select_component_1.CustomerPartnerSelectComponent, { label: (0, trans_1.trans)('order-sales-report.filter.customer-partner'), property: 'customerPartner', "control$": this.control })),
                        React.createElement("div", { className: "col-12" },
                            React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control })),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(supplier_filter_component_1.SupplierFilterComponent, { label: (0, trans_1.trans)('order-sales-report.filter.suppliers'), property: 'suppliers', "control$": this.control })),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(form_control_1.FormControl, { field: this.filterForm.fields.channel, type: select_input_form_control_type_1.SelectInputFormControlType },
                                React.createElement(select_input_1.SelectInput, { unclearable: true, multiple: false, tabIndex: 1, className: 'select-input-basic col pl-0' }))),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(list_date_range_filter_component_1.ListDateRangeFilterComponent, { startDate: moment().add(-1, 'month').toDate(), "control$": this.control }))) })),
            React.createElement(repository_list_1.RepositoryList, { onResponseChange: function (response) { return console.log(response.reduce(function (acc, item) { return acc + item.soldHuf; }, 0)); }, control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: 'admin/sales-by-products-report', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item, index) { return _this.renderItem(ctx, item, index); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function (ctx) { return (React.createElement(list_loader_1.ListLoader, null)); } }));
    };
    ProductSalesReportScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    ProductSalesReportScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: false },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 16 }, this.setHeaderCell('sku'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 16 }, this.setHeaderCell('title.hu'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('sellingUnitPlanAmount'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('sellingUnitAmount'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('sellingSumPriceHUF'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('sellingSumPriceEUR'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('lastSaleAt'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('supplier-price'))),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('calculated-price'))));
    };
    ProductSalesReportScreen.prototype.setHeaderCell = function (p) {
        var _this = this;
        return {
            sortValue: this.getSortValue(p),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: true,
            title: (0, trans_1.trans)("product-sales-report.list.table.header.".concat(p)),
            property: p
        };
    };
    ProductSalesReportScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    ProductSalesReportScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    ProductSalesReportScreen.prototype.renderItem = function (ctx, item, index) {
        var _this = this;
        var _a;
        var drs = item.drs ? '(DRS)' : (item === null || item === void 0 ? void 0 : item.drs2) ? '(VISSZAVÁLTHATÓ ÜVEG)' : '';
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper' },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: 'cursor-pointer', onClick: function () { return _this.toggleRow(item._id); } },
                React.createElement(table_body_row_1.TableBodyRow, { key: item._id, className: 'border-bottom-0' },
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 16 },
                        React.createElement("div", { className: 'chevron-container mr-2' },
                            React.createElement("i", { className: "fal ".concat(this.state.openedRows.includes("".concat(item._id)) ? 'fa-chevron-up' : 'fa-chevron-down') })),
                        React.createElement("span", null, item === null || item === void 0 ? void 0 : item.sku)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 16 },
                        React.createElement("div", null,
                            React.createElement("p", null,
                                React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "EAN"),
                                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.ean)),
                            React.createElement("p", null,
                                React.createElement("small", { className: 'color--neutral-8 fw-700 mr-2' }, "SKU"),
                                React.createElement("span", null, item === null || item === void 0 ? void 0 : item.sku)),
                            React.createElement("p", null, item === null || item === void 0 ? void 0 :
                                item.manufacturerTitle,
                                " ", item === null || item === void 0 ? void 0 :
                                item.title,
                                " ",
                                (item === null || item === void 0 ? void 0 : item.vintage) || '-',
                                " ",
                                (item === null || item === void 0 ? void 0 : item.capacity) || '',
                                " ",
                                drs))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                        React.createElement("span", null, item === null || item === void 0 ? void 0 :
                            item.planAmount,
                            " db")),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                        React.createElement("span", null, item === null || item === void 0 ? void 0 :
                            item.factAmount,
                            " db")),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                        React.createElement("span", null, (0, currency_parser_1.moneyFormat)(item === null || item === void 0 ? void 0 : item.soldHuf))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                        React.createElement("span", null, (0, currency_parser_1.moneyFormat)(item === null || item === void 0 ? void 0 : item.soldEur, 'EUR'))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                        React.createElement("span", null, (item === null || item === void 0 ? void 0 : item.lastSaleAt) ? moment(item === null || item === void 0 ? void 0 : item.lastSaleAt).format('YYYY.MM.DD. HH:mm') : '-')),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                        React.createElement("div", { className: 'row' }, (_a = item === null || item === void 0 ? void 0 : item.supplierPrices) === null || _a === void 0 ? void 0 : _a.map(function (supplierPrice) { return (React.createElement("div", { className: 'col-24', key: supplierPrice },
                            React.createElement("span", null, (0, currency_parser_1.moneyFormat)(supplierPrice)))); }))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                        React.createElement("span", null, (0, currency_parser_1.moneyFormat)(item.soldHuf - item.supplierPriceTotal))))),
            this.isOpened(item._id) && React.createElement(table_body_cell_1.TableBodyCell, { width: 100 }, this.renderChannels(item)));
    };
    ProductSalesReportScreen.prototype.toggleRow = function (id) {
        if (this.state.openedRows.includes(id)) {
            return this.setState({ openedRows: this.state.openedRows.filter(function (r) { return r !== id; }) });
        }
        return this.setState({ openedRows: __spreadArray(__spreadArray([], __read(this.state.openedRows), false), [id], false) });
    };
    ProductSalesReportScreen.prototype.isOpened = function (id) {
        return this.state.openedRows.includes(id);
    };
    ProductSalesReportScreen.prototype.renderChannels = function (item) {
        return React.createElement("div", { className: 'pl-5' },
            React.createElement("div", { className: 'display-flex mb-2' },
                React.createElement("strong", { className: 'mr-4' }, "HORECA: "),
                React.createElement("span", null, (0, currency_parser_1.hufFormat)(item === null || item === void 0 ? void 0 : item.channelSales.horeca))),
            React.createElement("div", { className: 'display-flex mb-2' },
                React.createElement("strong", { className: 'mr-4' }, "HIPER: "),
                React.createElement("span", null, (0, currency_parser_1.hufFormat)(item === null || item === void 0 ? void 0 : item.channelSales.hiper))),
            React.createElement("div", { className: 'display-flex mb-2' },
                React.createElement("strong", { className: 'mr-4' }, "B2B: "),
                React.createElement("span", null, (0, currency_parser_1.hufFormat)(item === null || item === void 0 ? void 0 : item.channelSales.b2b))),
            React.createElement("div", { className: 'display-flex mb-2' },
                React.createElement("strong", { className: 'mr-4' }, "B2C: "),
                React.createElement("div", { className: 'display-flex flex-column' },
                    React.createElement("div", null,
                        React.createElement("span", { className: 'mr-2' }, "HUF:"),
                        React.createElement("span", null, (0, currency_parser_1.hufFormat)(item === null || item === void 0 ? void 0 : item.channelSales.b2c.huf))),
                    React.createElement("div", null,
                        React.createElement("span", { className: 'mr-2' }, "EUR:"),
                        React.createElement("span", null, (0, currency_parser_1.moneyFormat)(item === null || item === void 0 ? void 0 : item.channelSales.b2c.eur, 'EUR'))))));
    };
    ProductSalesReportScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    return ProductSalesReportScreen;
}(abstract_component_1.AbstractComponent));
exports.ProductSalesReportScreen = ProductSalesReportScreen;
