"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CustomerPartnerReportScreen = void 0;
var repository_1 = require("@codebuild/glaze/libs/repository/repository");
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var table_1 = require("@codebuild/sprinkles/modules/codebuild/table/table");
var table_body_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-cell");
var table_body_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-body-row");
var table_header_cell_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-cell");
var table_header_row_1 = require("@codebuild/sprinkles/modules/codebuild/table/table-header-row");
var moment = require("moment");
var React = require("react");
var filter_group_1 = require("../../common/components/filter/filter-group");
var customer_partner_select_component_1 = require("../../common/components/list-search/customer-partner-select.component");
var list_date_range_filter_component_1 = require("../../common/components/list-search/list-date-range-filter.component");
var list_search_component_1 = require("../../common/components/list-search/list-search.component");
var user_filter_component_1 = require("../../common/components/list-search/user-filter.component");
var pagination_list_control_1 = require("../../common/components/repository-list/controls/pagination-list-control");
var list_empty_1 = require("../../common/components/repository-list/empty/list-empty");
var list_loader_1 = require("../../common/components/repository-list/loader/list-loader");
var repository_list_1 = require("../../common/components/repository-list/repository-list");
var currency_parser_1 = require("../../common/libs/currency-parser");
var total_box_1 = require("../../components/customer-partner-list/total-box");
var optional_component_1 = require("../../components/optional-component");
var report_download_1 = require("../../components/report-download");
var category_tab_1 = require("./libs/category-tab");
var CustomerPartnerReportScreen = (function (_super) {
    __extends(CustomerPartnerReportScreen, _super);
    function CustomerPartnerReportScreen() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.repository = repository_1.Repository.use('default');
        _this.control = new pagination_list_control_1.PaginationListControl({
            defaultData: {
                limit: 20,
                page: 0,
                query: '',
                sort: ''
            },
        });
        _this.state = {
            total: 0,
            statistics: null,
            statisticsLoading: false,
            openedRows: []
        };
        return _this;
    }
    CustomerPartnerReportScreen.prototype.render = function () {
        var _this = this;
        var _a, _b, _c;
        return React.createElement("div", { className: 'EnrichedCustomerPartnerReportScreen ListScreen' },
            React.createElement("div", { className: "row" },
                React.createElement("div", { className: "col-15 " },
                    React.createElement("div", { className: 'display-flex align-items-bottom mb-8' },
                        React.createElement("h2", null,
                            (0, trans_1.trans)('customer-partner-report.list.main.title'),
                            " ",
                            React.createElement("span", { className: 'fs-14' },
                                "(",
                                this.state.total,
                                ")")))),
                React.createElement("div", { className: "col-9" },
                    React.createElement("div", { className: 'display-flex align-items-center justify-content-end' },
                        React.createElement(report_download_1.ReportDownload, { url: '/admin/customer-partner-report/download', control: this.control }))),
                React.createElement("div", { className: 'col-15' }),
                React.createElement("div", { className: "col-9" },
                    React.createElement(list_search_component_1.ListSearchComponent, { "control$": this.control }))),
            React.createElement("div", { className: "justify-content-between align-items-center row pt-5" },
                React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3" },
                    React.createElement(total_box_1.TotalBox, { loading: this.state.statisticsLoading, data: (_a = this.state.statistics) === null || _a === void 0 ? void 0 : _a.cost, text: (0, trans_1.trans)('customer-partner-report.total-cost') })),
                React.createElement("div", { className: "col-lg-7 col-md-12 col-24 mb-3" },
                    React.createElement(total_box_1.TotalBox, { loading: this.state.statisticsLoading, data: (_b = this.state.statistics) === null || _b === void 0 ? void 0 : _b.profit, text: (0, trans_1.trans)('customer-partner-report.total-profit') })),
                React.createElement("div", { className: "col-lg-8 col-md-12 col-24 mb-3" },
                    React.createElement(total_box_1.TotalBox, { loading: this.state.statisticsLoading, data: (_c = this.state.statistics) === null || _c === void 0 ? void 0 : _c.revenue, text: (0, trans_1.trans)('customer-partner-report.total-revenue') }))),
            React.createElement("div", { className: "row pb-4" },
                React.createElement(filter_group_1.FilterGroup, { renderFilters: React.createElement("div", { className: "row" },
                        React.createElement("div", { className: "col-8" },
                            React.createElement(customer_partner_select_component_1.CustomerPartnerSelectComponent, { label: (0, trans_1.trans)('order-sales-report.filter.customer-partner'), property: 'customerPartner', "control$": this.control })),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(user_filter_component_1.UsersFilterComponent, { label: (0, trans_1.trans)('order-sales-report.filter.salesManager'), property: 'salesManager', "control$": this.control })),
                        React.createElement("div", { className: "col-8" },
                            React.createElement(list_date_range_filter_component_1.ListDateRangeFilterComponent, { startDate: moment().add(-1, 'month').format('YYYY-MM-DD'), "control$": this.control }))) })),
            React.createElement(repository_list_1.RepositoryList, { control: this.control, hideItemsWhileLoading: true, showLoading: function () { return true; }, showError: function () { return true; }, showItem: function () { return true; }, onResponseChange: function (response) { return _this.setTotals(response); }, onLoadingChange: function (statisticsLoading) { return _this.setState({ statisticsLoading: statisticsLoading }); }, onChange: function (ctx) { return _this.handleListChange(ctx); }, repository: repository_1.Repository.use('default'), endpoint: '/admin/customer-partner-report', renderItemsWrapper: function (ctx) { return _this.renderTable(ctx); }, renderItem: function (ctx, item) { return _this.renderItem(ctx, item); }, renderEmpty: function () { return (React.createElement(list_empty_1.ListEmpty, null)); }, renderLoading: function () { return (React.createElement(list_loader_1.ListLoader, null)); } }));
    };
    CustomerPartnerReportScreen.prototype.renderTable = function (ctx) {
        var _this = this;
        return React.createElement(table_1.Table, { className: 'table-basic', renderHeader: function () { return _this.renderTableHeader(); }, renderBody: function () { return ctx.renderItems(ctx); } });
    };
    CustomerPartnerReportScreen.prototype.renderTableHeader = function () {
        return React.createElement(table_header_row_1.TableHeaderRow, { sticky: true },
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 3 }, this.setHeaderCell(''), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 11 }, this.setHeaderCell('company'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('seller'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 11 }, this.setHeaderCell('name'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 11 }, this.setHeaderCell('phone'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 16 }, this.setHeaderCell('email'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 10 }, this.setHeaderCell('cost'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('profit'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('revenue'), { enableSort: false })),
            React.createElement(table_header_cell_1.TableHeaderCell, __assign({ width: 9 }, this.setHeaderCell('past-3-month'), { enableSort: false })));
    };
    CustomerPartnerReportScreen.prototype.renderItem = function (ctx, item) {
        var _this = this;
        var company = item.companyName;
        var seller = item.seller;
        var name = item.brandName;
        var phone = item.phone;
        var email = item.email;
        var cost = (0, currency_parser_1.hufFormat)(item.supplierPrice);
        var profit = (0, currency_parser_1.hufFormat)(this.getSafeProfit(item));
        var revenue = (0, currency_parser_1.hufFormat)(item.total);
        var past3Month = (0, currency_parser_1.hufFormat)(item.past3Month);
        return React.createElement(table_body_row_1.TableBodyRow, { className: 'flex-column wrapper', key: item._id },
            React.createElement(table_body_cell_1.TableBodyCell, { width: 100, className: 'cursor-pointer', onClick: function () { return _this.toggleRow(item._id); } },
                React.createElement(table_body_row_1.TableBodyRow, { key: item.customerPartnerId },
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 3 },
                        React.createElement("div", { className: 'chevron-container' },
                            React.createElement("i", { className: "fal ".concat(this.state.openedRows.includes("".concat(item._id)) ? 'fa-chevron-up' : 'fa-chevron-down') }))),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 11 },
                        React.createElement("span", null, company)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                        React.createElement("span", null, seller)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 11 },
                        React.createElement("span", null, name)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 11 },
                        React.createElement("span", null, phone)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 16 },
                        React.createElement("span", null, email)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 10 },
                        React.createElement("span", null, cost)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                        React.createElement("span", null, profit)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                        React.createElement("span", null, revenue)),
                    React.createElement(table_body_cell_1.TableBodyCell, { width: 9 },
                        React.createElement("span", null, past3Month)))),
            React.createElement(optional_component_1.OptionalComponent, { condition: this.isOpened(item._id), ifTrue: React.createElement(table_body_cell_1.TableBodyCell, { width: 100 },
                    React.createElement(category_tab_1.CategoryTab, { categories: item === null || item === void 0 ? void 0 : item.categories })) }));
    };
    CustomerPartnerReportScreen.prototype.toggleRow = function (id) {
        if (this.state.openedRows.includes(id)) {
            return this.setState({ openedRows: this.state.openedRows.filter(function (r) { return r !== id; }) });
        }
        return this.setState({ openedRows: __spreadArray(__spreadArray([], __read(this.state.openedRows), false), [id], false) });
    };
    CustomerPartnerReportScreen.prototype.isOpened = function (id) {
        return this.state.openedRows.includes(id);
    };
    CustomerPartnerReportScreen.prototype.setTotals = function (response) {
        var _a, _b, _c;
        this.setState({
            statistics: {
                cost: {
                    value: ((_a = response.totals) === null || _a === void 0 ? void 0 : _a.totalCost) || 0
                },
                profit: {
                    value: ((_b = response.totals) === null || _b === void 0 ? void 0 : _b.totalProfit) || 0
                },
                revenue: {
                    value: ((_c = response.totals) === null || _c === void 0 ? void 0 : _c.totalOrders) || 0
                }
            }
        });
    };
    CustomerPartnerReportScreen.prototype.getSafeProfit = function (_a) {
        var profit = _a.profit;
        if (!profit) {
            return 0;
        }
        if (profit < 1) {
            return 0;
        }
        return profit;
    };
    CustomerPartnerReportScreen.prototype.setHeaderCell = function (prop, enableSort) {
        var _this = this;
        if (enableSort === void 0) { enableSort = true; }
        return {
            sortValue: this.getSortValue(prop),
            onSort: function (property, value) { return _this.handleSort(property, value); },
            enableSort: enableSort,
            title: prop ? (0, trans_1.trans)("customer-partner-report.list.table.header.".concat(prop)) : '',
            property: prop
        };
    };
    CustomerPartnerReportScreen.prototype.handleListChange = function (ctx) {
        var _a, _b;
        var total = (_a = ctx.state) === null || _a === void 0 ? void 0 : _a.total;
        this.setState({ total: (_b = ctx.state) === null || _b === void 0 ? void 0 : _b.total });
        if (this.props.totalChange) {
            this.props.totalChange(total);
        }
    };
    CustomerPartnerReportScreen.prototype.handleSort = function (property, value) {
        this.control.sort(property, value);
    };
    CustomerPartnerReportScreen.prototype.getSortValue = function (property) {
        var _a;
        return ((_a = this.control.data.sort) === null || _a === void 0 ? void 0 : _a[property]) || 0;
    };
    return CustomerPartnerReportScreen;
}(abstract_component_1.AbstractComponent));
exports.CustomerPartnerReportScreen = CustomerPartnerReportScreen;
