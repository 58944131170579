"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryTab = void 0;
var abstract_component_1 = require("@codebuild/sprinkles/libs/libs/abstract.component");
var trans_1 = require("@codebuild/sprinkles/libs/translation/trans");
var lodash_1 = require("lodash");
var currency_parser_1 = require("../../../common/libs/currency-parser");
var React = require("react");
var CategoryTab = (function (_super) {
    __extends(CategoryTab, _super);
    function CategoryTab() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            categories: [],
            category: '',
            lists: []
        };
        return _this;
    }
    CategoryTab.prototype.componentDidMount = function () {
        var _a, _b;
        var categories = Object.keys(((_a = this.props) === null || _a === void 0 ? void 0 : _a.categories) || {});
        var lists = this.parseCategoryLists(this.props.categories);
        var defaultActive = (_b = categories === null || categories === void 0 ? void 0 : categories[0]) !== null && _b !== void 0 ? _b : '';
        this.setState({ categories: categories, lists: lists, category: defaultActive });
    };
    CategoryTab.prototype.componentDidUpdate = function (prevProps, prevState, snapshot) {
        var _a;
        if (!(0, lodash_1.isEqual)(prevProps.categories, this.props.categories)) {
            var categories = Object.keys(((_a = this.props) === null || _a === void 0 ? void 0 : _a.categories) || {});
            var lists = this.parseCategoryLists(this.props.categories);
            this.setState({ lists: lists, categories: categories });
        }
    };
    CategoryTab.prototype.render = function () {
        return (React.createElement("div", { className: 'w-100' },
            React.createElement("div", { className: 'display-flex justify-content-start' }, this.renderHeader()),
            React.createElement("div", { className: 'w-100 px-3' }, this.renderContent())));
    };
    CategoryTab.prototype.renderHeader = function () {
        var _this = this;
        return this.state.categories.map(function (category) {
            var stateClass = _this.state.category === category ? 'color--primary-6' : 'hover-opacity';
            return React.createElement("div", { className: "hover-opacity p-3 ".concat(stateClass), onClick: function () { return _this.setState({ category: category }); }, key: category },
                React.createElement("span", { className: 'fw-600 fs-11 uppercase' }, category));
        });
    };
    CategoryTab.prototype.parseCategoryLists = function (categories) {
        return Object.entries(categories || {}).map(function (_a) {
            var _b = __read(_a, 2), category = _b[0], products = _b[1];
            return ({ category: category, products: products });
        });
    };
    CategoryTab.prototype.groupPurchases = function (products) {
        var e_1, _a;
        var aggregatedProducts = [];
        var _loop_1 = function (product) {
            var existingOne = aggregatedProducts.find(function (item) {
                var isGroupable = product.sku === item.sku && product.title === item.title;
                return isGroupable;
            });
            if (existingOne) {
                existingOne.factAmount += product.factAmount;
                existingOne.soldHuf += product.soldHuf;
                return "continue";
            }
            aggregatedProducts.push(product);
        };
        try {
            for (var products_1 = __values(products), products_1_1 = products_1.next(); !products_1_1.done; products_1_1 = products_1.next()) {
                var product = products_1_1.value;
                _loop_1(product);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (products_1_1 && !products_1_1.done && (_a = products_1.return)) _a.call(products_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return aggregatedProducts;
    };
    CategoryTab.prototype.renderContent = function () {
        var _this = this;
        var list = this.state.lists.find(function (l) { return l.category === _this.state.category; });
        var products = this.groupPurchases((list === null || list === void 0 ? void 0 : list.products) || []);
        return React.createElement("div", { className: 'row w-100' },
            React.createElement("div", { className: 'col-24 row' },
                React.createElement("div", { className: 'col-6' },
                    React.createElement("span", { className: 'fs-11 fw-600 uppercase' }, (0, trans_1.trans)('customer-partner-report.sub.list.sku'))),
                React.createElement("div", { className: 'col-6' },
                    React.createElement("span", { className: 'fs-11 fw-600 uppercase' }, (0, trans_1.trans)('customer-partner-report.sub.list.title'))),
                React.createElement("div", { className: 'col-6' },
                    React.createElement("span", { className: 'fs-11 fw-600 uppercase' }, (0, trans_1.trans)('customer-partner-report.sub.list.amount'))),
                React.createElement("div", { className: 'col-6' },
                    React.createElement("span", { className: 'fs-11 fw-600 uppercase' }, (0, trans_1.trans)('customer-partner-report.sub.list.price')))),
            products.map(function (product) { return _this.renderProductsTableRow(product); }));
    };
    CategoryTab.prototype.renderProductsTableRow = function (product) {
        var sku = (product === null || product === void 0 ? void 0 : product.sku) || '-';
        var title = (product === null || product === void 0 ? void 0 : product.title) || '-';
        return React.createElement("div", { className: 'col-24 row my-1', key: product._id },
            React.createElement("div", { className: 'col-6' },
                React.createElement("span", { className: 'fs-12' }, sku)),
            React.createElement("div", { className: 'col-6' },
                React.createElement("span", { className: 'fs-12' }, title)),
            React.createElement("div", { className: 'col-6' },
                React.createElement("span", { className: 'fs-12' }, product === null || product === void 0 ? void 0 : product.factAmount)),
            React.createElement("div", { className: 'col-6' },
                React.createElement("span", { className: 'fs-12' }, (0, currency_parser_1.hufFormat)(product === null || product === void 0 ? void 0 : product.soldHuf))));
    };
    return CategoryTab;
}(abstract_component_1.AbstractComponent));
exports.CategoryTab = CategoryTab;
