"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Popup = void 0;
var React = require("react");
var Popup = (function (_super) {
    __extends(Popup, _super);
    function Popup() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            show: false,
        };
        return _this;
    }
    Popup.prototype.renderPopup = function () {
        var _a, _b;
        var className = (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.popupClassName) !== null && _b !== void 0 ? _b : 'price-info-icon';
        return React.createElement("div", { className: className }, this.props.content);
    };
    Popup.prototype.showInfo = function () {
        this.setState({ show: true });
    };
    Popup.prototype.hideInfo = function () {
        this.setState({ show: false });
    };
    Popup.prototype.handleMobileOnClick = function () {
        this.state.show ? this.hideInfo() : this.showInfo();
    };
    Popup.prototype.render = function () {
        var _this = this;
        if (!this.props.content || !this.props.show) {
            return null;
        }
        return React.createElement("div", { className: "".concat(this.props.wrapperClasses, " position-relative display-contents isolate") },
            React.createElement("i", { onMouseOver: function () { return _this.showInfo(); }, onMouseOut: function () { return _this.hideInfo(); }, onTouchStart: function () { return _this.handleMobileOnClick(); }, className: "".concat(this.props.iconClassName, " hover-opacity-strong") }),
            this.state.show && this.renderPopup());
    };
    return Popup;
}(React.Component));
exports.Popup = Popup;
